<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { useUserStore } from '~/stores/user';
import type { ApiResponse } from '~/utils/ApiResponse';
import type { accounts_updateUser } from '~/api/generated';
import { AccountsService } from '~/api/generated';

const mail = ref<string>('');
const forwardEmail = ref<boolean>(true);
const showMessage = ref<boolean>(false);
const messageText = ref<string>('');
const messageClass = ref<string>('');

const showForwardEmaiConfig = import.meta.env.VITE_FORWARD_EMAILS?.toLowerCase() === 'true';

const userStore = useUserStore();
if (userStore.user?.privateEmail !== '')
  mail.value = userStore.user?.privateEmail as string;

if (userStore.user?.forwardEmailSet !== undefined)
  forwardEmail.value = userStore.user?.forwardEmailSet as boolean;

const updateAccountData = ref<ApiResponse<any>>({ data: undefined, status: undefined });

function validateMail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i;
  return re.test(email);
}

const buttonEnabled = computed(() => {
  return mail.value !== '' && validateMail(mail.value);
});

async function updateMailAddress() {
  if (buttonEnabled.value) {
    const mailData: accounts_updateUser = { privateEmail: mail.value, forwardEmail: forwardEmail.value };
    AccountsService.updateAccount(mailData)
      .then(result => updateAccountData.value = { data: result, status: 'success' })
      .catch(() => updateAccountData.value = { data: undefined, status: 'error' });
  }
}

watch(updateAccountData, () => {
  const status = updateAccountData.value.status;

  if (status === 'success') {
    showMessage.value = true;
    messageText.value = 'E-Mail Adresse wurde erfolgreich geändert.';
    messageClass.value = 'alert-success';
    if (userStore.user) {
      userStore.user.privateEmail = mail.value;
      userStore.user.forwardEmailSet = forwardEmail.value;
    }
  }
  else if (status === 'error') {
    showMessage.value = true;
    messageText.value = 'E-Mail Adresse konnte nicht geändert werden.';
    messageClass.value = 'alert-danger';
  }
});
</script>

<template>
  <BaseModal
    title="Private E-Mail-Adresse ändern"
    @submit="updateMailAddress"
  >
    <template #body>
      <template v-if="!showMessage">
        <div class="px.2 pb-4">
          Sollten Sie keinen Zugriff mehr auf Ihr Konto haben, können Sie sich Informationen zum Zurücksetzen
          Ihres Passwortes an diese E-Mail-Adresse schicken lassen.
        </div>
        <o-input

          v-model="mail"
          name="mail"
          placeholder="Ihre E-Mail-Adresse"
          icon="fa6-solid-envelope"
          type="mail"
        />
        <div v-if="showForwardEmaiConfig" class="pt-5">
          <o-checkbox
            v-model="forwardEmail"
            class="cursor-pointer"
          >
            E-Mail-Weiterleitung auf diese Adresse einrichten
          </o-checkbox>
        </div>
      </template>
      <div
        v-if="showMessage"
        :class="messageClass"
      >
        {{ messageText }}
      </div>
    </template>
    <template #footer>
      <div
        v-if="!showMessage"
        class="flex justify-end"
      >
        <button
          :disabled="!buttonEnabled"
          class="btn btn-success"
          type="submit"
        >
          E-Mail-Adresse speichern
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
