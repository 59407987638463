import { defineStore } from 'pinia';
import type { JwtPayload } from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';

// import type { LoginRefreshTokenPayload, LoginResult, LoginUser } from '~/api/generated/api';
import { useRouter } from 'vue-router';
import type { login_User, login_refreshTokenPayload } from '~/api/generated';
import { LoginService } from '~/api/generated';

let logoutOnBrowserClose = true;
if (import.meta.env.VITE_LOGOUT_ON_BROWSER_CLOSE !== undefined)
  logoutOnBrowserClose = import.meta.env.VITE_LOGOUT_ON_BROWSER_CLOSE?.toLowerCase() === 'true';

export const useUserStore = defineStore('user', () => {
  const isAuthenticated = ref<boolean>(false);
  const user = ref<null | login_User>(null);
  const accessToken = ref<null | string>(null);
  const refreshToken = ref<null | string>(null);
  const logoutUrl = ref<null | string>(null);

  const router = useRouter();

  function setUser(u: login_User) {
    isAuthenticated.value = true;
    user.value = u;
  }

  function setAccessToken(token: string) {
    accessToken.value = token;
  }

  function setRefreshToken(token: string) {
    refreshToken.value = token;
  }

  function setLogoutUrl(url: string) {
    logoutUrl.value = url;
  }

  function setPasswordSet(passwordSet: boolean) {
    user.value!.passwordSet = passwordSet;
  }

  function getGroups() {
    return user.value?.groups || [];
  }

  async function getToken() {
    if (!isAuthenticated.value) {
      return '';
    }

    // check if token is present
    if (!refreshToken.value && isAuthenticated.value) {
      // no token, logout... ');
      router.push('/logout');
      return '';
    }

    // check if token is expired
    if (refreshToken.value) {
      const decodedRefreshToken = jwtDecode<JwtPayload>(refreshToken.value || '', {});

      if (decodedRefreshToken.exp && decodedRefreshToken.exp < Date.now() / 1000) {
        console.error('refresh token expired, logout... ');
        router.push('/logout');
        return '';
      }
    }
    else {
      // no token, logout... ');
      router.push('/logout');
      return '';
    }

    let needRefresh = false;
    if (accessToken.value) {
      const decodedAccessToken = jwtDecode<JwtPayload>(accessToken.value);

      if (decodedAccessToken.exp && decodedAccessToken.exp < Date.now() / 1000) {
        needRefresh = true;
      }
      else {
        return accessToken.value as string;
      }
    }
    else {
      needRefresh = true;
    }

    if (
      needRefresh
      && refreshToken.value
      && accessToken.value !== null
    ) {
      accessToken.value = null;

      // console.info('token expired, refreshing... ');

      const token: login_refreshTokenPayload = { refresh_token: refreshToken.value as string };
      const result = await LoginService.refreshToken(token);

      if (result) {
        isAuthenticated.value = true;
        accessToken.value = result.access_token as string;
        return accessToken.value as string;
      }
      else {
        console.error('could not refresh token');
        router.push('/logout');
        return '';
      }
    }

    if (needRefresh) {
      isAuthenticated.value = false;
    }

    return accessToken.value as string;
  }

  function logout() {
    isAuthenticated.value = false;
    user.value = null;
    accessToken.value = '';
    refreshToken.value = null;
  }

  return {
    user,
    logoutUrl,
    accessToken,
    refreshToken,
    isAuthenticated,
    logout,
    setUser,
    setRefreshToken,
    setAccessToken,
    setPasswordSet,
    getGroups,
    getToken,
    setLogoutUrl,
  };
}, {
  persist: {
    storage: logoutOnBrowserClose ? sessionStorage : localStorage,
  },
});
